<script lang="ts">
  import PropertyMapExplorer from "./components/PropertyMapExplorer.svelte";
  import PropertyMapPrint from "./components/PropertyMapPrint.svelte";
  import { view } from "./util/uistores";
</script>

{#if "print" === $view}
  <PropertyMapPrint />
{:else}
  <PropertyMapExplorer />
{/if}
