import { derived, type Readable } from "svelte/store";
import { fetchPolicy, fetchProperty, fetchSpaceStatus, fetchUnits } from "./api";
import { param } from "./params";
import type { Property, Policy, Unit, Prices } from "../types";

export const policyId = param("policy");

export const policy = derived<Readable<string>, Policy>(policyId, function updater($policyId, set) {
  if (!$policyId) return set(null);
  fetchPolicy($policyId).then(set);
});

export const propertyId = derived<[Readable<string>, typeof policy], string>(
  [param("property"), policy],
  ([$id, $policy]) => $id || ($policy?.scope as string)
);

export const property = derived(propertyId, function updater($propertyId, set) {
  if (!$propertyId) return set(null);
  fetchProperty($propertyId).then(set);
}) as Readable<Property>;

export const units = derived<Readable<string>, Record<string, Unit>>(
  propertyId,
  ($propertyId, set) => {
    if (!$propertyId) return set(null);

    fetchUnits($propertyId)
      .then(function (json) {
        const meta = json.units["for"][$propertyId];
        const items = meta.items;

        for (const [k, v] of Object.entries(items)) {
          items[k] = json.items[v as string] ?? json.items[k] ?? v;
        }

        return items;
      })
      .then(set);
  }
);

const status = derived([propertyId], function updater([$propertyId], set) {
  if (!$propertyId) return set(null);
  // console.log(
  //   "$valid=",
  //   $valid,
  //   parseISO($valid),
  //   format(new Date($valid), "yyyy-MM-dd'T'00:00:00'/'")
  // );
  set(null);
  fetchSpaceStatus($propertyId).then(set);
}) as Readable<any>;

export const spaces = derived(status, ($status, set) => {
  if (!$status) return set(null);

  set(
    Object.values($status.spaces.items).reduce(
      (result: Record<string, any>, item: string | any) => {
        item = $status.items[item as string] || item;

        for (const k1 of ["prices", "permitted"]) {
          item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
        }

        result[item.id] = item;

        return result;
      },
      {}
    )
  );
  // for (const item of Object.values($policy?.spaces?.items ?? {}) as any[]) {
  //   for (const k1 of ["prices", "permitted"]) {
  //     item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];
  //   }
}) as Readable<Record<string, any>>;

export const pricingFor = derived<[typeof property, typeof status], Record<string, Prices>>(
  [property, status],
  ([$property, $state]) => {
    const data: Record<string, Prices> = $state?.prices?.["for"];

    if (!data) return data;

    for (const item of Object.values(data)) {
      // expand any itms
      for (const [k1, v1] of Object.entries(item.items)) {
        item.items[k1] = $state.items[v1 as unknown as string] || $state.items[k1] || v1;
      }
    }

    return data;
  }
);
