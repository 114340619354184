<script context="module" lang="ts">
  import { derived, writable, type Readable, type Writable } from "svelte/store";

  function debounce(fn) {
    let raf;

    return (...args) => {
      if (raf) {
        console.log("debounced");
        return;
      }

      raf = requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }

  //const mapstyle = writable(null) as Writable<StyleSpecification>;

  //const mapinstance = writable(null) as Writable<VectorGLMap>;

  // const resolvedMapStyle = derived(mapstyle, function($style, set) {

  //   if(typeof $style == "string")

  // });

  function getMapStyleLoaded($map: Readable<VectorGLMap>): Readable<StyleSpecification> {
    return derived($map, function start($map, set) {
      if (!$map) return set(null);

      if ($map.isStyleLoaded()) set($map.getStyle() as StyleSpecification);

      //console.log("setting up style derived store", $map);

      function onstyledata(e: MapStyleDataEvent) {
        //console.log("styledata=", e);
        //if (e.target.isStyleLoaded())
        set(e.target.getStyle());
      }
      function onstyleload(e: MapStyleDataEvent) {
        //console.log("style.load=", e);
        set(e.target.getStyle());
      }

      function onstyledataloading(e: MapStyleDataEvent) {
        set(null);
        //console.log("onstyleloading", e);
        // e.target.once("styledata", onstyledata);
        //e.target.once("style.load", onstyleload);
      }

      // catch any scheduled changes
      //$map.once("styledata", onstyledata);
      //$map.once("style.load", onstyleload);

      // catch any changes
      $map.on("styledataloading", onstyledataloading);
      $map.on("styledata", onstyledata);
      //$map.on("style.load", onstyleload);

      return function stop() {
        $map.off("styledataloading", onstyledataloading);
        $map.off("styledata", onstyledata);
        $map.off("style.load", onstyleload);
      };
    });

    // return readable(null, function start(set) {
    //   function onstyledataloading() {
    //     set(null);
    //     $map.once("style.load", function (e: MapDataEvent) {
    //       console.log("style.load=", e);
    //       set(e.target.getStyle());
    //     });
    //   }

    //   $map.on("styledataloading", onstyledataloading);

    //   return function stop() {
    //     $map.off("styledataloading", onstyledataloading);
    //   };
    // }) as Readable<StyleSpecification>;

    // return derived(readable($map), ($map, set) => {
    //   if (!$map) return set(null);
    //   set(null);
    //   $map.on("styledataloading", () => {
    //     set(null);
    //     $map.once("style.load", function (e: MapDataEvent) {
    //       console.log("style.load=", e);
    //       set(e.target.getStyle());
    //     });
    //   });
    // }) as Readable<StyleSpecification>;
  }

  //mapinstance.subscribe(($value) => console.log("map=", $value));

  // const mapstyleloaded = derived(mapinstance, ($map, set) => {
  //   if (!$map) return set(null);
  //   set(null);
  //   $map.on("styledataloading", () => {
  //     set(null);
  //     $map.once("style.load", function (e: MapDataEvent) {
  //       console.log("style.load=", e);
  //       set(e.target.getStyle());
  //     });
  //   });

  //   //$map.on("styledata", () => set(true || $map.isStyleLoaded()));
  // }) as Readable<StyleSpecification>;

  //mapstyleloaded.subscribe(($value) => console.log("mapstyleloaded=", $value));

  // const loadedMapStyle = mapstyleloaded;

  // const loadedMapStyle = derived(
  //   [mapinstance, mapstyleloaded],
  //   ([$map, $loaded]) => $map && $loaded && $map.getStyle()
  // );

  //loadedMapStyle.subscribe(($value) => console.log("loadedMapStyle=", $value));

  // const selectionSource = derived(
  //   [mapinstance, loadedMapStyle],
  //   ([$map, $style]) => $map && $map.getStyle() && $map?.getSource("selection")
  // );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }

  function createMap(init: MapOptions, library?: string) {
    return new (engines[library] ?? engine).Map(init);
    // const $map = new engine.Map(init);
    // //const $map = new mapbox.Map(init);
    // mapinstance.set($map);

    // return function destroy() {
    //   $map.remove;
    //   mapinstance.set(null);
    // };
  }
</script>

<script lang="ts">
  import { onDestroy, onMount, setContext, tick } from "svelte";
  import resize from "svelte-use-resize-observer";
  // import { Feature } from "geojson";
  import { setSvelteContext, engine, engines } from "./mapping";
  import type {
    StyleSpecification,
    VectorGLMap,
    MapOptions,
    MapStyleDataEvent,
    LngLatBoundsLike,
  } from "./mapping";
  export let style: string;
  export let styledata: StyleSpecification = null; // this way we can reactive on loaded
  export let MAP: VectorGLMap = null;
  export let maxbounds: LngLatBoundsLike | undefined | null = null;
  export let fitbounds: LngLatBoundsLike | undefined | null = null;
  export let minzoom = 0;
  export let maxzoom = 22;
  let library: string = "maplibre";
  let loadedMap: Writable<VectorGLMap> = writable(null);
  let loadedMapStyle = getMapStyleLoaded(loadedMap);

  let classname: string = "";
  export { classname as class, library as engine };

  const styleinput = writable<string | StyleSpecification>(null);
  const styleresolved = derived<Readable<string | StyleSpecification | null>, StyleSpecification>(
    styleinput,
    ($style, set) => {
      if (null == $style) {
        set(null);
      } else if (typeof $style == "string") {
        set(null);
        fetch($style)
          .then((res) => (res.ok ? res.json() : null))
          .then((json) => set(json as StyleSpecification))
          .catch((e) => set(null));
      } else {
        set($style);
      }
    }
  );

  // let getSourceFeatures: Function | null | undefined;
  // let setFeatureState: Function | null | undefined;
  // let removeFeatureState: Function | null | undefined;

  //export { getFeatureState, setFeatureState, removeFeatureState };

  $: if (loadedMapStyle && $loadedMapStyle) styledata = $loadedMapStyle;
  // $: if (!maxbounds) maxbounds = (styledata as any)?.metadata?.bounds;

  $: if (maxbounds && MAP) {
    //console.log("setting max bounds = ", maxbounds);
    MAP.setMaxBounds(maxbounds as LngLatBoundsLike);
  }

  $: if (fitbounds && MAP) {
    //console.log("setting max bounds = ", maxbounds);
    MAP.fitBounds(fitbounds as LngLatBoundsLike, {
      animate: true,
      duration: 450,
    });
  }

  $: if (null != styledata?.pitch) changePitch(MAP, styledata.pitch);

  function changePitch($map: VectorGLMap, $pitch: number) {
    //console.log("changepitch", $map, $pitch);
    if (null == $map) return;
    if ($pitch === 0 && $map.getPitch() > 0) $map.setPitch(0);
    if ($pitch > 0 && $map.getPitch() === 0) $map.setPitch($pitch);
  }

  // $: getFeatureState = $mapinstance?.getFeatureState;
  // $: setFeatureState = $mapinstance?.setFeatureState;
  // $: removeFeatureState = $mapinstance?.removeFeatureState;

  let container: HTMLElement = null;

  let css = true;

  $: if (!MAP && container && css)
    loadedMap.set(
      (MAP = createMap(
        {
          container: container, // container ID
          attributionControl: false,
          style: "", // style URL
          // center: [-74.5, 40], // starting position [lng, lat]
          // zoom: 9, // starting zoom
          //hash: true,
          maxZoom: maxzoom,
          minZoom: minzoom,
        },
        library
      ))
    );

  // $: if (style && MAP) {
  //   console.log("setting style to=", style);
  //   MAP.setStyle(style);
  // }

  $: if (style) styleinput.set(style); // call any time style changes
  $: if (MAP && $styleresolved) {
    console.log("setting style to=", $styleresolved);
    MAP.setStyle($styleresolved);
  }

  $: if (MAP) {
    MAP.on("error", (e) => console.log("map.error=", e));
    MAP.on("load", (e) => console.log("map.load=", e));
  }

  $: if (MAP && minzoom) MAP.setMinZoom(minzoom);
  $: if (MAP && maxzoom) MAP.setMaxZoom(maxzoom);

  onDestroy(function () {
    MAP?.remove();
    loadedMap?.set((MAP = null));

    // set stores
    //MAP = null;
  });

  //$: if (MAP) loadedMap.set(MAP);

  //$: if (MAP)
  setSvelteContext({
    map: loadedMap,
    style: loadedMapStyle,
    styledmap: derived([loadedMapStyle, loadedMap], ([$style, $map], set) => {
      if (!$style || !$map) return set(null);
      tick().then(() => set($map));
    }),
  });

  // requestanimationframe debounce the resize events
  const onresize = debounce(() => {
    console.log("resizing map");
    MAP?.resize();
  });
</script>

<figure use:resize on:resize={onresize} class="map {classname}" bind:this={container}>
  {#if MAP}
    <figcaption><slot /></figcaption>
  {/if}
  <!-- <slot name="contents" /> -->
</figure>
