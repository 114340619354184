<script lang="ts">
  import { propertyId, property, spaces, units, pricingFor } from "../util/propertystores";
  import PropertyMap from "./PropertyMap.svelte";
  import MapLevelSelect from "./map/MapLevelSelect.svelte";
  import MapFeatureHighlight from "./map/MapFeatureHighlight.svelte";
  import type { MapGeoJSONFeature } from "./map/mapping";
  import MapPopup from "./map/MapPopup.svelte";
  import RecordItem from "./RecordItem.svelte";
  import MapFeatureState from "./map/MapFeatureState.svelte";
  import { hover } from "../util/behaviorstores";
  import { query } from "../util/router";
  import { param, params } from "../util/params";
  import MapFeatureHover from "./map/MapFeatureHover.svelte";
  import MapFeatureClick from "./map/MapFeatureClick.svelte";
  import PropertyLevelList from "./PropertyLevelList.svelte";
  import MapInspector from "./map/MapInspector.svelte";
  import MapFeatureDef from "./map/MapFeatureDef.svelte";
  import PropertyHighlightList from "./PropertyMapHighlightList.svelte";
  import LocalServices from "./LocalServices.svelte";
  import MapCompass from "./map/MapCompass.svelte";
  import { derived } from "svelte/store";
  import type { Readable } from "svelte/store";
  import { format } from "date-fns";
  import PropertyMapStyleList from "./PropertyMapStyleList.svelte";

  const level = param("level", true);
  let levels: Record<string, any>;
  //let selected: Record<string, boolean>;
  let hovered: MapGeoJSONFeature;
  const selectable = derived(param("selectable"), ($value) => $value !== "false");
  const permitted = derived(param("permitted"), ($value) => $value !== "false");
  const today = format(new Date(), "yyyy-MM-dd");
  const valid = param("valid", true, today);
  const selected: Readable<string> = derived(
    [param("space"), param("tenant"), param("unit"), param("selected")],
    (ids) => ids.find((i) => !!i)
  ) as Readable<string>;
  let highlight: (MapGeoJSONFeature) => boolean;
  let hoveredItem: {
    type: string;
    id: string;
  };
  //let spaceState: Record<string, any>;

  const records = {
    media: true,
    vehicle: true,
    unit: true,
    tenant: true,
    space: true,
  };

  const clear = {
    ...Object.keys(records).reduce((result, k) => {
      result[k] = null;
      return result;
    }, {}),
    selected: null,
  };

  $: hoveredItem =
    $spaces?.[hovered?.properties?.["ref:boss:subject"]] ??
    (($units as any)?.items ?? $units)?.[hovered?.properties?.["ref:boss:subject"]];

  let inspecting: MapGeoJSONFeature[];
  let freezeInspector: boolean;

  const style = param("style", true, "cool");
  const branch = param("branch");
  const builder = param("builder");
  const gltf = param("gltf");
  const carto = param("carto");
  const parking = param("parking", true, "permits");
  const inspect = param("inspect");

  function onlevelchange(e: CustomEvent<string>) {
    query("level", e.detail);
  }

  $: title = $property ? `${$property.name} - Carto Previewer - Community Boss` : "Loading...";

  $: document.title = title;

  const spaceState = derived(
    [spaces, selected, selectable, permitted],
    ([$spaces, $selected, $selectable, $permitted]) => {
      return Object.values($spaces ?? {}).reduce(
        (result, item, i) => {
          //if (item.permitted) {
          //if (0 === i) delete result["*"];
          //console.log("space item =", item);
          if ($permitted)
            result[item.id] = {
              permit:
                // $parking !== "permits"
                //   ? null
                //   :
                item.permitted?.permitted ? undefined : "no",
              selectable: $selectable,
              selected: $selected == item.id,
            };
          //}
          return result;
        },
        Object.entries(($selected && { [$selected]: true }) || {}).reduce(
          (state, [k, v]) => {
            state[k] = {
              selected: v,
            };
            return state;
          },
          {
            "*": { permit: null, selectable: $selectable, selected: false },
          }
        )
      ) as Record<string, any>;
    }
  );
</script>

<header>
  <h1>{$property?.name ?? "Loading…"}</h1>
  <form>
    <fieldset>
      <!-- <legend>Level</legend> -->
      <PropertyLevelList {levels} level={$level} on:level={onlevelchange} />
    </fieldset>
    <fieldset>
      <!-- <legend>Style</legend> -->
      <PropertyMapStyleList style={$style} on:change={(e) => query(e.detail)} />
    </fieldset>
    <a href="/properties/{$propertyId}/print?style=light">Open Print Generator</a>

    <!-- <MapLevelSelect
          {levels}
          bind:level={$level}
          on:change={(e) => query("level", e.detail)}
        /> -->

    <fieldset>
      <!-- <legend>Style</legend> -->
      <LocalServices />
    </fieldset>

    <fieldset>
      <legend>Highlight</legend>
      <PropertyHighlightList spaces={$spaces} bind:highlight />
    </fieldset>
  </form>
</header>
<PropertyMap
  property={$property?.id}
  level={$level}
  selected={$selected}
  theme={$style}
  on:level={onlevelchange}
  bind:levels
  minzoom={17}
  branch={$branch}
  builder={$builder}
  gltf={$gltf}
  carto={$carto}
  engine="mapbox"
>
  <slot />

  <!-- highlighters can work on any feature -->
  <MapFeatureHighlight
    filter={[
      "all",
      ["==", ["geometry-type"], "Polygon"],
      ["==", ["get", "amenity"], "parking_space"],
    ]}
    {highlight}
  />
  <!--specifically boss state-->
  <MapFeatureState
    state={(feature) => $spaceState[feature.properties["ref:boss:subject"]] ?? $spaceState["*"]}
    filter={["all", ["==", ["geometry-type"], "Polygon"], ["has", "ref:boss:subject"]]}
  />

  <!-- specifically boss hovers -->
  <MapFeatureHover
    filter={["all", ["==", ["geometry-type"], "Polygon"], ["has", "ref:boss:subject"]]}
    bind:hovered
  >
    {#if $hover && hoveredItem}
      <MapPopup feature={hovered}>
        <h1>
          <RecordItem url={false} item={hoveredItem} />
        </h1>
        <ul>
          {#each Object.values($pricingFor?.[hoveredItem.id]?.items ?? {}) as item}
            <li>
              <data class="price" value={item.id}>{item.description}</data>
            </li>
          {/each}
          <!-- {#each Object.entries(recordPolicies?.[hoveredItem.id] ?? {}) as [id, title]}
            <li><data class="policy" value={id}>{title}</data></li>
          {/each} -->
        </ul>
      </MapPopup>
    {/if}
  </MapFeatureHover>
  <!-- selection can only be boss object -->
  <MapFeatureClick
    filter={["all", ["==", ["geometry-type"], "Polygon"], ["has", "ref:boss:subject"]]}
    on:click={(e) => {
      console.log("map click=", e);
      query({
        ...clear,
        [e.detail.properties.type]: e.detail.properties["ref:boss:subject"],
      });
    }}
  />
  {#if !!$inspect}
    <MapInspector bind:freeze={freezeInspector} bind:features={inspecting} />
  {/if}
  <MapCompass />
</PropertyMap>
{#if $inspect}
  <aside class="inspector">
    {#if freezeInspector}
      <button type="button" on:click={(e) => (freezeInspector = false)}>Unlock</button>
    {/if}
    {#each inspecting ?? [] as feature}
      <MapFeatureDef {feature} />
    {/each}
  </aside>
{/if}
