<script lang="ts">
  export let item;
  const attrs = [
    "covered",
    "indoor",
    "outdoor",
    "capacity:charging",
    "capacity:disabled",
    "capacity:lift",
    "capacity:bicycle",
    "size",
  ];

  const values = {
    size: (item) => {
      if ("motorcycle" !== item.size && "motorcycle" === item.parking_space)
        return "motorcycle";
      return item.size;
    },
    covered: (item) => {
      if (
        "carport" === item.parking || // todo remove this one
        "carports" === item.parking
      )
        return "carport";
      if (
        "yes" === item.outdoor &&
        "carport" !== item.parking && // todo remove this one
        "carports" !== item.parking &&
        "yes" !== item.covered &&
        "carport" !== item.covered
      )
        return "uncovered";

      return item.covered;
    },
  };
</script>

{#if item.color}<data
    class="color"
    value={item.color.toLowerCase()}
  />{/if}{#each attrs.filter((key) => !!item[key]) as key}<data
    class={key.replace(":", "-")}
    value={values[key.replace(":", "-")]?.(item) ?? item[key]}
  />{/each}
