import { derived, type Readable, writable } from "svelte/store";
//import { writable } from "@parkingboss/svelte-storage";

export const params = writable<Record<string, string>>({});

function paramStore(
  selector,
  onlyUpdateForRealValues = false,
  defaultValue: string | null = null
): Readable<string> {
  var value = null;
  return derived(params, (params, set) => {
    if (!params) return;
    var newValue = selector(params);
    if (!newValue && null != defaultValue) newValue = defaultValue;
    if (!newValue && !value) return;
    if (!newValue && onlyUpdateForRealValues) return;
    //console.log("setting new paramstore value", newValue);
    if (newValue != value) set((value = newValue));
  });
}

const stores: Record<string, Readable<string>> = {};

export const param = (
  property: string,
  onlyUpdateWhenSet = false,
  defaultValue: string | null = null
): Readable<string> =>
  stores[property] ||
  (stores[property] = paramStore(
    ($params) => $params[property],
    onlyUpdateWhenSet,
    defaultValue
  ));
