<script lang="ts">
  import { onDestroy } from "svelte";
  import {
    type MapGeoJSONFeature,
    type VectorGLMap,
    type Popup,
    type LngLatLike,
    getSvelteContext,
  } from "./mapping";
  import { engine } from "./mapping";
  import polylabel from "polylabel/polylabel";

  export let feature: MapGeoJSONFeature | GeoJSON.Feature;
  export let position: LngLatLike | undefined | null = undefined;

  const { styledmap } = getSvelteContext();

  $: point =
    (feature &&
      feature.geometry.type == "Point" &&
      feature.geometry.coordinates) ||
    (feature &&
      feature.geometry.type == "Polygon" &&
      polylabel(feature.geometry.coordinates)) ||
    position;

  let element: HTMLElement;
  const poppop: Popup = new engine.Popup({
    closeButton: false,
    closeOnClick: false,
    anchor: "bottom-left",
    className: "boss-overview",
  });

  function init(
    $map: VectorGLMap,
    $position: LngLatLike,
    $content: string | HTMLElement
  ) {
    //console.log("init popup", $position, $content);
    if (!$map || !$position || !$content) {
      poppop.remove();
      return;
    }
    if (!$position) return;
    if (!$content) return;

    poppop.setLngLat($position);
    if (typeof $content === "string") poppop.setHTML($content as string);
    else poppop.setDOMContent($content as HTMLElement);
    poppop.addTo($map);
  }

  $: init($styledmap, point, element);

  onDestroy(function () {
    poppop?.remove();
  });
</script>

<aside bind:this={element}>
  <slot />
</aside>
