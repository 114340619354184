<script lang="ts">
  import { params } from "../util/params";
  import { query } from "../util/router";
</script>

<ul
  on:change={({ target }) =>
    target instanceof HTMLSelectElement
      ? query(target.name, target.value)
      : target instanceof HTMLInputElement
      ? query(target.name, target.checked ? target.value : null)
      : null}
>
  <li>
    <input
      type="checkbox"
      name="inspect"
      value="true"
      checked={$params.inspect}
      id="tools-inspect"
    /><label for="tools-inspect">Inspector</label>
  </li>
  <li>
    <label for="carto-src">2D</label>
    <select name="carto" id="carto-src" value={$params.carto || ""}>
      <option value="">production</option>
      <option value="https://carto-develop.communityboss.workers.dev">develop</option>
      <option value="http://localhost:9000">localhost</option>
    </select>
  </li>
  <li>
    <label for="imagery-src">Imagery</label>
    <select name="imagery" id="imagery-src" value={$params.imagery || ""}>
      <option value="">default</option>
      <option value="mapbox">mapbox</option>
      <option value="virtualearth">virtualearth</option>
      <option value="arcgis">arcgis</option>
    </select>
  </li>
  <li>
    <label for="gltf-src">3D</label>
    <select name="gltf" id="gltf-src" value={$params.gltf || ""}>
      <option value="">production</option>
      <option value="https://carto-service-uscn-develop.azurewebsites.net/api/gltf">develop</option>
      <option value="http://localhost:9002/api/gltf">localhost</option>
    </select>
  </li>
</ul>
