import App from "./App.svelte";
//import { api } from "./util/auth";
import { router, end } from "./util/router";

import "./styles/app.scss";

const app = new App({
  target: document.body ?? document.querySelector("body"),
});

router("/properties/:property/:view", end);
router("/properties/:property", end);
router("/:view", end);

router("/", end);

router.start({
  dispatch: true,
  popstate: true,
  click: true,
  hashbang: false,
});
