<script lang="ts">
  import { type GeoJSONFeature } from "maplibre-gl";
  import { comparer } from "../../util/sort";
  import { type MapGeoJSONFeature } from "./mapping";

  export let feature: MapGeoJSONFeature;
</script>

{#if feature}
  <dl class="feature">
    <dt>type</dt>
    <dd>{feature.geometry.type}</dd>
    {#if feature?.layer}
      <dt>layer</dt>
      <dd>{feature.layer.id}</dd>
    {/if}
    {#each Object.entries(feature.properties ?? {}).sort(comparer("0")) as [key, value]}
      <dt>{key}</dt>
      <dd>{value}</dd>
    {/each}
    {#if feature?.state}
      {#each Object.entries(feature.state ?? {}).sort(comparer("0")) as [key, value]}
        <dt>{key}</dt>
        <dd>{value}</dd>
      {/each}
    {/if}
  </dl>
{/if}
