import pick from "lodash-es/pick";

import { api, auth, client } from "./auth";
import { throttle } from "lodash-es";
import { format } from "date-fns";

export { api };

// update auth header as auth changes
export let user = "self";
export let authHeader = "";
export let credentials;

auth.subscribe(function ($auth) {
  credentials = $auth;
  authHeader = $auth && `&Authorization=${$auth.type} ${$auth.token}`;
  user = $auth?.sub || "self";
});

export function authorize(url) {
  if (typeof url === "string") url = new URL(url);
  if (url) {
    url.searchParams.set(
      "Authorization",
      (credentials && `${credentials.type} ${credentials.token}`) || ""
    );
    return url;
  }
  return "&Authorization=" + (authHeader || "");
}

export function viewpoint(offset?: number) {
  return encodeURIComponent(
    format(new Date().getTime() + (offset || 0), "yyyy-MM-dd'T'HH:mm:ssxxx")
  );
}

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

export async function fetchProperty(property) {
  const res = await fetch(`${base()}/properties?viewpoint=${viewpoint()}&property=${property}`);
  const json = await res.json();

  const item = resolveProperty(property, json);
  return item;

  //return json;
}

export async function fetchPolicy(policy) {
  const res = await fetch(
    `${base()}/permits/policies/issue?policy=${policy}&viewpoint=${viewpoint()}&public=true&admin=false&disabled=false`
  );

  const json = await res.json();

  //statify(json);

  var item = resolvePolicy(policy, json);

  return item;

  // for (const v of Object.values(json.policies["for"][property] ?? {})) {
  //   const policy = json.items[v] ?? v;
  //   policy.spaces = json.spaces["for"][policy.policy];
  // }

  // return json;
}

export async function fetchUnits(scope) {
  const res = await fetch(`${base()}/units?property=${scope}&viewpoint=${viewpoint()}`);

  const json = await res.json();

  //statify(json);

  return json;
  // for (const v of Object.values(json.policies["for"][property] ?? {})) {
  //   const policy = json.items[v] ?? v;
  //   policy.spaces = json.spaces["for"][policy.policy];
  // }

  // return json;
}

export async function fetchSpaceStatus(scope: string, valid?: string) {
  if (!valid) valid = viewpoint() + "/";
  else valid = encodeURIComponent(valid);

  var url = `${base()}/locations/${scope}/permits/spaces/summary?prices=true&viewpoint=${viewpoint()}&valid=${valid}`;
  //console.log("spaces=", url);

  const res = await fetch(url);
  //console.log("spaces res", res);
  const json = await res.json();

  // for (const k1 of ["prices", "permitted"]) {
  //   for (const [k2, v2] of Object.entries(json[k1]?.["for"] ?? {})) {
  //     const item = json.items[k2];
  //     console.log(k1, k2, v2, item);
  //     if (item) item[k1] = v2;
  //   }
  // }

  // copy prices to set by subject
  // if (json.prices) {
  //   for (const [id, v] of Object.entries(json.prices.items)) {
  //     if (typeof v !== "string") continue;
  //     const price = (json.prices.items[id] =
  //       json.items[v] || json.items[id] || v);

  //     if (price.subjects) {
  //       for (const subject of Object.values(price.subjects)) {
  //         json.prices["for"] = json.prices["for"] || {};
  //         json.prices["for"][subject] = json.prices["for"][subject] || [];
  //         json.prices["for"][subject].push(price);
  //       }
  //     }
  //   }
  //   //console.log("prices=", json.prices);
  // }

  return json;
}

function resolvePolicy(item, state) {
  if (!item) return item;

  const items = state.items || state;

  item = items[item] || item;

  for (const key of ["statistics", "pricing", "metered", "entry", "spaces", "units"]) {
    //const value =
    item[key] = state[key]?.["for"]?.[item.id] || state[key]?.["for"]?.[item.subject];
    // if (value?.items)
    //   for (const [k2, v2] of Object.entries(value.items))
    //     value.items[k2] = state[v2] ?? state[k2] ?? v2;
  }
  for (const key of ["units", "spaces"]) {
    if (item[key])
      item[key] = Object.entries(item[key].items ?? item[key]).reduce(
        (result, [k, v]) => {
          if (!v) return result;
          result.items[k] = items[v as string] ?? items[k] ?? v;
          return result;
        },
        item[key].items ? item[key] : { items: {} }
      );
  }

  // if (item.units)
  //   item.units = Object.entries(item.units?.items ?? item.units).reduce(
  //     (result, [k, v]) => {
  //       if (!v) return result;
  //       result.items[k] = items[v] ?? items[k] ?? v;
  //       return result;
  //     },
  //     item.units.items ? item.units : { items: {} }
  //   );

  // item.statistics =
  //   get(state, ["statistics", "for", item.id]) ||
  //   get(state, ["statistics", "for", item.subject]);
  // item.pricing =
  //   get(state, ["pricing", "for", item.id]) ||
  //   get(state, ["pricing", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // for (let [id, v] of Object.entries(metersFromPolicy(item) || {})) {
  //   v = items[v] || v;
  //   if (!v || !v.principals) continue;
  //   for (const [id2, v2] of Object.entries(v.principals)) {
  //     v.principals[id2] = items[v2] || v2;
  //   }
  // }

  //item.property = resolveProperty(item.location, state);

  //console.log("policy=", item);

  return item;
}

function resolveProperty(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  return resolveAddress(item, state);
}

function resolveAddress(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  //console.log("address state=", state);
  item.address = items[item.address] || item.address;
  return item;
}
