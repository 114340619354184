<script lang="ts" context="module">
  let tb: Threebox;
  const layerid = "threebox";
</script>

<script lang="ts">
  import { onMount } from "svelte";
  import { getSvelteContext, type Model3D, type VectorGLMap } from "./mapping";
  import { Threebox } from "threebox-plugin";
  export let model: Model3D;
  let modelstate: Model3D;
  let modelurl: string;

  const { map } = getSvelteContext();
  const styledmap = map;

  function layer($map, $model) {
    console.log("attempting 3d layer", $map, $model?.url);
    $map.addLayer({
      id: layerid,
      type: "custom",
      renderingMode: "3d",
      onAdd: function (map, gl) {
        console.log("onadd=", map, gl, tb);
        tb = window["tb"] = new Threebox(map, gl, {
          defaultLights: true,
          //sky: true,
          // enableTooltips: true,
          // enableHelpTooltips: true,
          // enableDraggingObjects: true,
          //passiveRendering: false,
        });
        console.log("onadd=", map, gl, tb);
        var options = {
          obj: $model.url,
          type: $model.type,
          //scale: 1,
          units: "meters",
          anchor: "none",
          //sky: true,
          //enableTooltips: true,
          //adjustment: { z: -1 },
          //adjustment: 0,
          //normalize: false,
          //scale: { x: -1,  },
          rotation: { x: 90, y: 180 }, //default rotation
        };
        const [w, s, e, n] = $model.bbox;
        const origin = [w, s];
        tb.loadObj(options, function (model) {
          console.log("model=", model);
          //const center = map.getCenter();
          model.setCoords(origin);
          console.log("0,0", origin);
          tb.add(model);
          // const axesHelper = new THREE.AxesHelper(100);
          // tb.scene.add(axesHelper);
        });
      },
      render: function (gl, matrix) {
        tb?.update();
      },
    });
  }

  // we want to detect specific changes in order to change model
  $: if (model?.url != modelurl) {
    modelurl = model?.url;
    modelstate = model;
  }

  $: console.log("modelurl update=", modelurl);

  function state($map: VectorGLMap, $model?: Model3D) {
    if (!$map) return;

    // this should only be called if something changes the core state
    cleanup($map);

    if ($model) layer($map, $model);

    // cleanup($layer); // cleanup previous map
    // MAP = $map; // set
    // // events
    // if ($layer) {
    //   MAP?.on("mouseenter", $layer, onmouseenter);
    //   MAP?.on("mouseleave", $layer, onmouseleave);
    // } else {
    //   MAP?.on("mousemove", onmouseover);
    // }
  }

  function cleanup($map: VectorGLMap) {
    if (!$map) return; // no map to cleanup

    if ($map?.getLayer(layerid)) $map?.removeLayer(layerid);

    // remove the model layer?

    // events
    // if ($layer) {
    //   MAP?.off("mousemove", $layer, onmouseover);
    //   MAP?.off("mouseenter", $layer, onmouseenter);
    //   MAP?.off("mouseleave", $layer, onmouseleave);
    // }
    // MAP?.off("mousemove", onmouseover);
    // MAP?.off("mouseenter", onmouseenter);
    // MAP?.off("mouseleave", onmouseleave);

    // hover(MAP);
    //init(MAP, null);
  }

  $: state($styledmap, modelstate);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup($styledmap);
      //unsubscribe();
    };
  });
</script>
