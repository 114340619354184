<script context="module">
  import identity from "lodash-es/identity";
  //import { href } from "../util/router";
  function href(item) {
    return "";
  }
</script>

<script>
  import RecordExtras from "./RecordExtras.svelte";

  export let url = false;
  export let item;
</script>

{#if url}
  <a href={true === url ? href(item) : url}>
    <data
      class={["id", item.type, item._type, item.format].filter(identity).join(" ")}
      value={item.id || item.key}>{item.display}<RecordExtras {item} /></data
    >
  </a>
{:else}
  <data
    class={["id", item.type, item._type, item.format].filter(identity).join(" ")}
    value={item.id || item.key}>{item.display}<RecordExtras {item} /></data
  >
{/if}

<style lang="scss">
</style>
