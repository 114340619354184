<script lang="ts">
  import { onMount } from "svelte";
  import {
    getSvelteContext,
    type MapGeoJSONFeature,
    type MapLayerMouseEvent,
    type MapMouseEvent,
  } from "./mapping";

  import type { VectorGLMap } from "./mapping";
  import MapFeatureDef from "./MapFeatureDef.svelte";

  const { styledmap } = getSvelteContext();
  let MAP: VectorGLMap;

  export let filter: any[] | ((feature: MapGeoJSONFeature | GeoJSON.Feature) => Boolean) =
    undefined;
  let layer: string | undefined | null = undefined;

  export let features: MapGeoJSONFeature[];
  export let freeze = false;

  // let hovered: MapGeoJSONFeature[];

  // $: features = hovered;

  function hover($map: VectorGLMap, $features?: MapGeoJSONFeature[]) {
    if (!$map) return;
    if (freeze) return;
    features = $features;
  }

  function onmouseover(e: MapMouseEvent) {
    //console.log("space map hover popup onmouseover=", e);
    const value = e.target
      .queryRenderedFeatures(
        e.point,
        filter instanceof Function
          ? undefined
          : {
              filter,
            }
      )
      .filter(filter instanceof Function ? filter : () => true);
    hover(e.target, value);
  }

  function onmouseenter(e: MapLayerMouseEvent) {
    const value = filter instanceof Function ? e.features?.filter(filter) : e.features;

    hover(e.target, value);

    // e.target.getCanvas().style.cursor = "";
    // if (!e.features) {
    //   if (hovered) {
    //     e.target.setFeatureState(hovered, {
    //       hover: false,
    //     });
    //   }
    //   return;
    // }
    // //console.log("mouseenter=", e);

    // for (const feature of e.features) {
    //   if (!feature.state?.selectable) continue;
    //   if (hovered && hovered.id == feature.id) return;

    //   if (hovered) {
    //     e.target.setFeatureState(hovered, {
    //       hover: false,
    //     });
    //   }
    //   e.target.setFeatureState((hovered = feature), {
    //     hover: true,
    //   });
    //   e.target.getCanvas().style.cursor = "pointer";
    //   //console.log("hovered=", feature);
    //   break;
    // }
  }
  function onmouseleave(e) {
    //if (!e.features) return;
    //console.log("onmouseleave=", e);
    // e.target.getCanvas().style.cursor = "";
    // if (hovered)
    //   e.target.setFeatureState(hovered, {
    //     hover: false,
    //   });
    // hovered = null;
    hover(e.target);
  }

  function onclick(e: MapMouseEvent) {
    freeze = true;
  }

  function init($map: VectorGLMap, $layer?: string) {
    cleanup($layer); // cleanup previous map
    MAP = $map; // set

    const canvas = MAP?.getCanvas();
    if (canvas?.style) canvas.style.cursor = "crosshair";

    // events
    if ($layer) {
      MAP?.on("mouseenter", $layer, onmouseenter);
      MAP?.on("mouseleave", $layer, onmouseleave);
      MAP?.on("click", $layer, onclick);
    } else {
      MAP?.on("mousemove", onmouseover);
      MAP?.on("click", onclick);
    }
  }
  function cleanup($layer?: string) {
    if (!MAP) return; // no map to cleanup

    const canvas = MAP.getCanvas();
    if (canvas?.style) canvas.style.cursor = "";

    // events
    if ($layer) {
      MAP?.off("mousemove", $layer, onmouseover);
      MAP?.off("mouseenter", $layer, onmouseenter);
      MAP?.off("mouseleave", $layer, onmouseleave);
      MAP?.off("click", $layer, onclick);
    }
    MAP?.off("mousemove", onmouseover);
    MAP?.off("mouseenter", onmouseenter);
    MAP?.off("mouseleave", onmouseleave);
    MAP?.off("click", onclick);

    hover(MAP);
    MAP = null; //set
  }

  $: init($styledmap, layer);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup(layer);
      //unsubscribe();
    };
  });

  //   onMount(function () {
  //     map.on("mouseenter", layer, onmouseenter);
  //     map.on("mouseleave", layer, onmouseleave);
  //     return function destroy() {
  //       if (!map) return;
  //       map.off("mouseenter", layer, onmouseenter);
  //       map.off("mouseleave", layer, onmouseleave);
  //     };
  //   });

  //   map.on("mouseenter", function (e) {
  //     if (!e.features) return;
  //     console.log("mouseenter=", e.features);
  //     // for(const feature of e.features) {
  //     //     feature.
  //     // }
  //     // var item =
  //     //   !!e.features && e.features.length > 0
  //     //     ? e.features[0]
  //     //     : feature(e.target, e.point);
  //     // if (!item || !item.id) return;
  //     // mapUI.setFeatureState(
  //     //   { source: "property", id: (id = item.id) },
  //     //   { hover: true }
  //     // );
  //   });
</script>

<slot />
