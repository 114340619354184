<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let style: string = "cool";
  const eventing = createEventDispatcher();
  function change(key: string, value: string) {
    eventing("change", {
      [key]: value,
    });
  }
  const onchange = ({ target, currentTarget }) =>
    currentTarget instanceof HTMLSelectElement
      ? change(currentTarget.name, currentTarget.value)
      : currentTarget instanceof HTMLInputElement
      ? change(currentTarget.name, currentTarget.checked ? currentTarget.value : null)
      : target instanceof HTMLSelectElement
      ? change(target.name, target.value)
      : target instanceof HTMLInputElement
      ? change(target.name, target.checked ? target.value : null)
      : null;
</script>

<ul on:change={onchange}>
  <li>
    <input type="radio" name="style" value="cool" checked={style == "cool"} id="style-cool" /><label
      for="style-cool">Cool</label
    >
  </li>
  <li>
    <input
      type="radio"
      name="style"
      value="coolhybrid"
      checked={style == "coolhybrid"}
      id="style-cool-hybrid"
    /><label for="style-cool-hybrid">Cool Hybrid</label>
  </li>
  <li>
    <input
      type="radio"
      name="style"
      value="cool.3d"
      checked={style == "cool.3d"}
      id="style-cool-3d"
    /><label for="style-cool-3d">Cool 3D</label>
  </li>
  <li>
    <input
      type="radio"
      name="style"
      value="satellite"
      checked={style == "satellite"}
      id="style-satellite"
    /><label for="style-satellite">Satellite</label>
  </li>
  <li>
    <input
      type="radio"
      name="style"
      value="light"
      checked={style == "light"}
      id="style-light"
    /><label for="style-light">Simplified</label>
  </li>
  <li>
    <input type="radio" name="style" value="dark" checked={style == "dark"} id="style-dark" /><label
      for="style-dark">Dark</label
    >
  </li>
</ul>
