<script lang="ts" context="module">
  type FeatureStateResolver = (
    feature: MapGeoJSONFeature
  ) => Record<string, any>;
</script>

<script lang="ts">
  import {
    createEventDispatcher,
    getContext,
    onDestroy,
    onMount,
    tick,
  } from "svelte";
  import { getSvelteContext, type MapEvent } from "./mapping";
  import type {
    MapGeoJSONFeature,
    MapSourceDataEvent,
    VectorGLMap,
  } from "./mapping";
  import throttle from "lodash-es/throttle";

  const eventing = createEventDispatcher();

  const { styledmap } = getSvelteContext();

  let MAP: VectorGLMap;

  //export let source: string | undefined | null = null;
  //export let sourceLayer: string | undefined | null = null;
  export let state: (feature: MapGeoJSONFeature) => Record<string, any>;
  export let filter: any[] | undefined | null = [
    "all",
    ["==", ["geometry-type"], "Polygon"],
    ["has", "ref:boss:subject"],
  ];

  //$: console.warn("passed state change=", state);

  function processSelection(
    $map: VectorGLMap,
    $features: MapGeoJSONFeature[],
    $state: FeatureStateResolver
  ) {
    // console.log(
    //   "processSelectionFeatures=",
    //   $map.querySourceFeatures(source),
    //   $map.getSource(source),
    //   selectable,
    //   selected
    // );
    // const source = $map.getSource("selection") as GeoJSONSource;
    // const data = source?.serialize()?.data;
    // console.log("data=", data);

    //console.log("features=", source, $features);
    for (const feature of $features) {
      //console.log("feature=", feature);
      if (!feature.id) continue;
      if (!feature.source) continue;

      //if ("Polygon" !== feature.geometry.type) continue;
      const featureState =
        $state instanceof Function
          ? $state(feature)
          : $state?.[feature.properties?.["ref:boss:subject"]] ?? $state?.["*"];

      //if (featureSelectable) console.log("selectable feature=", feature);

      // console.log("feature=", feature, {
      //   ...$map.getFeatureState(feature),
      //   ...featureState,
      // });

      // $map.setFeatureState(feature, {
      //   ...$map.getFeatureState(feature),
      //   ...featureState,
      // });
      $map.setFeatureState(feature, featureState);
    }
  }

  function selection(
    $map: VectorGLMap,
    $filter: any[],
    $state: FeatureStateResolver,
    $source: string = null
  ) {
    if (!$map) {
      console.log("no usable map yet, can't query");
      return;
    }
    // console.log(
    //   "about to start processing state, map=",
    //   $map,
    //   "filter=",
    //   $filter,
    //   "state=",
    //   $state,
    //   $source
    // );
    processSelection(
      $map,
      $source
        ? $map
            .querySourceFeatures($source, {
              sourceLayer: null,
              filter: $filter,
            })
            .map(function (f) {
              if (!f.source) f.source = $source;
              return f;
            })
        : $map.queryRenderedFeatures(null, {
            filter: $filter,
          }),
      $state
    );
  }

  const throttled = throttle(selection, 1 * 1000, {
    leading: true,
    trailing: true,
  });

  const onidle = function (e: MapEvent) {
    //console.log("selectable onidle", e);
    if (!e.target.isStyleLoaded()) return; // don't use idle while not loaded
    //onsole.log("about to selectable onidle");
    selection(e.target, filter, state);
  };

  const onsourcedata = function (e: MapSourceDataEvent) {
    //console.log("selectable onsourcedata=", e.sourceId, e.isSourceLoaded);
    if (e.isSourceLoaded)
      tick().then(() => selection(e.target, filter, state, e.sourceId));
  };

  function init(
    $map: VectorGLMap
    //, $filter: any[], $state: typeof state
  ) {
    //console.log("init for map", $map);
    cleanup(); // cleanup previous map
    MAP = $map; // set

    // events
    MAP?.on("sourcedata", onsourcedata);
    //MAP?.on("idle", onidle);
    //MAP?.on("click", onclick);

    //selection(MAP, $filter, $state);
  }
  function cleanup() {
    if (!MAP) return; // no map to cleanup

    // events
    MAP?.off("sourcedata", onsourcedata);
    MAP?.off("idle", onidle);
    //MAP?.off("click", onclick);

    selection(MAP, filter, state);

    // set
    MAP = null;
  }

  $: init(
    $styledmap
    //, filter, state
  );
  $: selection($styledmap, filter, state); // if filters or state change we need to pick that up

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup();
      //unsubscribe();
    };
  });

  //   onMount(function () {
  //     map.on("sourcedata", onsourcedata);
  //     map.on("idle", onidle);
  //     map.on("click", onclick);

  //     return function destroy() {
  //       map?.off("sourcedata", onsourcedata);
  //       map?.off("idle", onidle);
  //       map?.off("click", onclick);
  //     };
  //   });

  //$: selection($styledmap, filter, state);
</script>

<slot />
