<script lang="ts">
  import { onMount } from "svelte";
  import { debounce } from "lodash-es";
  import { getSvelteContext, type LngLatLike, type MapEvent, type VectorGLMap } from "./mapping";

  const { map } = getSvelteContext();

  let MAP: VectorGLMap;

  let heading = 0;
  let pitch = 0;

  function onmapviewchange(e: MapEvent) {
    const map = e.target;
    // don't block view
    requestAnimationFrame(() => {
      heading = map.getBearing();
      pitch = map.getPitch();
    });
  }

  function defaultOrientation($map: VectorGLMap) {
    if (!$map) return;
    const style = $map.getStyle();
    if (!style) return;
    $map.easeTo(
      {
        bearing: style.bearing,
        pitch: style.pitch,
        center: style.center as LngLatLike,
        zoom: style.zoom,
      },
      {
        duration: 400,
      }
    );
  }

  function state($map: VectorGLMap) {
    // cleanup first
    cleanup($map);

    if (!$map) return;

    $map.on("rotateend", onmapviewchange);
    $map.on("rotate", onmapviewchange);
    $map.on("rotatestart", onmapviewchange);

    $map.on("pitchend", onmapviewchange);
    $map.on("pitch", onmapviewchange);
    $map.on("pitchstart", onmapviewchange);
  }

  function cleanup($map?: VectorGLMap) {
    // cleanup any existing event state
    MAP?.off("rotateend", onmapviewchange);
    MAP?.off("rotate", onmapviewchange);
    MAP?.off("rotatestart", onmapviewchange);

    MAP?.off("pitchend", onmapviewchange);
    MAP?.off("pitch", onmapviewchange);
    MAP?.off("pitchstart", onmapviewchange);

    // update the current map
    MAP = $map;
  }

  // update on any map updates
  $: state($map);

  onMount(function () {
    // make sure we cleanup everything
    return function destroy() {
      cleanup();
    };
  });
</script>

<button class="heading" type="button" on:click={(e) => defaultOrientation($map)}
  ><data class="heading" value={heading} style="transform:rotateZ({360 - heading}deg);" /></button
>
