<script lang="ts">
  import MapFeatureState from "./MapFeatureState.svelte";
  import type { MapGeoJSONFeature } from "./mapping";

  export let filter: any[] | null = null;
  export let highlight: (feature: MapGeoJSONFeature) => boolean;
</script>

<MapFeatureState
  {filter}
  state={(feature) => ({
    highlight: !!highlight?.(feature),
  })}
>
  <slot />
</MapFeatureState>
