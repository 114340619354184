<script lang="ts" context="module">
  const carto = param("carto");
  const level = param("level", true);
  const style = param("style", true, "light");
  const branch = param("branch");
  const builder = param("builder");
  const gltf = param("gltf");
</script>

<script lang="ts">
  import { propertyId, property } from "../util/propertystores";
  import PropertyMap from "./PropertyMap.svelte";
  import { query } from "../util/router";
  import { param } from "../util/params";
  import printer from "print-js";
  import jspdf from "jspdf";

  import PropertyLevelList from "./PropertyLevelList.svelte";
  import type { VectorGLMap } from "./map/mapping";
  import { localhost } from "../util/behaviorstores";

  let levels: Record<string, any>;
  let hidpi = false;
  let orientation: "portrait" | "landscape" = "portrait";
  let renderer: VectorGLMap;
  let printing = false;
  let pdfing = false;

  function onlevelchange(e: CustomEvent<string>) {
    query("level", e.detail);
  }

  $: title = $property ? `${$property.name} - Print Map - Community Boss` : "Loading...";

  $: document.title = title;

  $: pagetitle = `${$property?.name ?? ""} - ${levels?.[$level]?.["level:ref"] ?? ""}`;

  $: portrait = "portrait" == orientation;

  //document.documentElement.classList.add("print");

  //   var cssPagedMedia = (function () {
  //     var style = document.createElement("style");
  //     document.head.appendChild(style);
  //     return function (rule) {
  //       style.innerHTML = rule;
  //     };
  //   })();

  //   $: cssPagedMedia("@page {size: letter " + orientation + "}");

  function renderBase64Image($map: VectorGLMap): Promise<string> {
    return new Promise(function (resolve, reject) {
      const pixelRatio = renderer.getPixelRatio?.();

      renderer.once("render", function (e) {
        console.log("render map");
        // call on render b/c we get data
        const img = e.target.getCanvas().toDataURL();

        resolve(img);

        console.log("setting pixel ratio", pixelRatio);
        renderer.setPixelRatio?.(pixelRatio);
        renderer.triggerRepaint(); // needed?
      });

      console.log("setting pixel ratio=", 3);
      renderer.setPixelRatio?.(3); // 3 should be the right pixel ratio for 300dpi
      renderer.triggerRepaint(); // needed?
    });
  }

  // no longer listen to browser print
  async function print() {
    console.log("start print", renderer);

    if (!renderer) return; // no renderer

    printing = true;

    const img = await renderBase64Image(renderer);

    // print image
    printer({
      printable: img,
      type: "image",
      base64: true,
      header: pagetitle,
      imageStyle: "width:100%;max-width: 100%;",
      headerStyle: "font-size:12pt; font-weight:normal; font-family:sans-serif; text-align:center;",
      style: `@page {size: letter ${orientation}; margin:0.5in;};`,
    });

    printing = false;

    //MAP._render();
  }

  async function pdf() {
    console.log("start print", renderer);

    if (!renderer) return; // no renderer
    pdfing = true;

    const img = await renderBase64Image(renderer);

    var doc = new jspdf({
      orientation,
      unit: "in",
      format: "letter",
      putOnlyUsedFonts: true,
    });

    doc.text(pagetitle, portrait ? 4.25 : 5.5, 0.5, {
      align: "center",
      baseline: "top",
    });

    //addImage(imageData, format, x, y, width, height, alias, compression, rotation)
    if (portrait) doc.addImage(img, 0.5, 1, 7.5, 9.5);
    else doc.addImage(img, 0.5, 1, 9.5, 7.5);

    doc.save("map.pdf");

    pdfing = false;
  }
</script>

<header>
  <form>
    <fieldset class="buttons">
      <button disabled={!renderer || printing} class="print" type="button" on:click={(e) => print()}
        >Print</button
      >
      <button disabled={!renderer || pdfing} class="pdf" type="button" on:click={(e) => pdf()}
        >PDF</button
      >
    </fieldset>
    <fieldset>
      <ul>
        <li>
          <input
            type="radio"
            name="orientation"
            value="portrait"
            bind:group={orientation}
            id="orientation-portrait"
          /><label for="orientation-portrait">Portrait</label>
        </li>
        <li>
          <input
            type="radio"
            name="orientation"
            value="landscape"
            bind:group={orientation}
            id="orientation-landscape"
          /><label for="orientation-landscape">Landscape</label>
        </li>
        {#if $localhost}
          <li
            on:change={({ target }) =>
              target instanceof HTMLSelectElement
                ? query(target.name, target.value)
                : target instanceof HTMLInputElement
                ? query(target.name, target.checked ? target.value : null)
                : null}
          >
            <label for="carto-src">2D</label>
            <select name="carto" id="carto-src" value={$carto || ""}>
              <option value="">production</option>
              <option value="https://carto-develop.communityboss.workers.dev">develop</option>
              <option value="http://localhost:9000">localhost</option>
            </select>
          </li>
        {/if}
        <!-- <li>
          <input type="checkbox" name="hidpi" bind:checked={hidpi} id="hidpi" /><label for="hidpi"
            >2x resolution</label
          >
        </li> -->
      </ul>
    </fieldset>
    <fieldset>
      <!-- <legend>Style</legend> -->
      <ul
        on:change={({ target }) =>
          target instanceof HTMLInputElement && target.checked && query(target.name, target.value)}
      >
        <li>
          <input
            type="radio"
            name="style"
            value="light"
            checked={$style == "light"}
            id="style-light"
          /><label for="style-light">Simplified</label>
        </li>
        <li>
          <input
            type="radio"
            name="style"
            value="cool"
            checked={$style == "cool"}
            id="style-cool"
          /><label for="style-cool">Detailed</label>
        </li>

        <li>
          <input
            type="radio"
            name="style"
            value="satellite"
            checked={$style == "satellite"}
            id="style-satellite"
          /><label for="style-satellite">Satellite</label>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <legend>Level</legend>
      <PropertyLevelList {levels} level={$level} on:level={onlevelchange} />
    </fieldset>
  </form>
</header>
<main>
  <section class="page {orientation}" class:hidpi>
    <header>
      <h1>{$property?.name ?? ""} - {levels?.[$level]?.["level:ref"] ?? ""}</h1>
    </header>
    <PropertyMap
      property={$propertyId}
      theme={$style || "light"}
      level={$level}
      on:level={onlevelchange}
      bind:levels
      minzoom={16}
      branch={$branch}
      builder={$builder}
      gltf={$gltf}
      carto={$carto}
      engine="maplibre"
      bind:renderer
    />
  </section>
</main>
