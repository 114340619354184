import page from "page";
import { params } from "./params";
import { isArray } from "lodash-es";

// qs mechanics
page("*", function (ctx, next) {
  // don't run auth, we're running out of band
  // ctx.querystring has decoding applied to it, unreliable
  //ctx.url = new URL(ctx.path, document.baseURI);
  const query = new URL(ctx.path, document.baseURI).searchParams; //new URLSearchParams(location.search);
  // hash isn't decoded (similar to URL.hash)
  const hash = new URLSearchParams(ctx.hash);
  //console.log("ctx=", ctx);
  ctx.query = [...query.keys()].reduce(
    (result, k) => {
      var values = query.getAll(k);
      if (values?.length === 1) {
        result[k] = decodeURIComponent(values[0]);
      } else if (values?.length) {
        result[k] = values;
      }
      return result;
    },
    [...hash.keys()].reduce((result, k) => {
      var values = hash.getAll(k);
      if (values?.length === 1) {
        result[k] = decodeURIComponent(values[0]);
      } else if (values?.length) {
        result[k] = values;
      }
      return result;
    }, {})
  );

  console.log(ctx.query);

  next();
});

export default page;

export const router = page;

export function state(ctx, next?) {
  params.set(Object.assign({}, ctx.params, ctx.query));
  if (!!next) next();
}

export function route(url) {
  page(url);
}

export function end(ctx) {
  state(ctx);
}

export function query(keyOrMap: string | Record<string, string>, value?, path?) {
  if (arguments.length == 1 && typeof keyOrMap == "object") {
    const qs = new URLSearchParams(window.location.search);
    for (const [k, v] of Object.entries(keyOrMap as Record<string, string>)) {
      if (null != v && isArray(v)) {
        qs.delete(k);
        for (const valueItem of v) qs.append(k, valueItem);
      } else if (null != v) qs.set(k, v);
      else qs.delete(k);
    }
    let q = qs.toString();
    if (q) q = "?" + q;
    return route((path || window.location.pathname) + q);
  }

  const qs = new URLSearchParams(window.location.search);

  if (null != value && isArray(value)) {
    qs.delete(keyOrMap as string);
    for (const valueItem of value) qs.append(keyOrMap as string, valueItem);
  } else if (null != value) qs.set(keyOrMap as string, value);
  else qs.delete(keyOrMap as string);
  let q = qs.toString();
  if (q) q = "?" + q;
  route((path || window.location.pathname) + q);
}
